<template>
  <v-form ref="offerForm" @submit.prevent="" class="signup">
    <div class="signup__title">Sign Up</div>
    <div class="signup__slide" v-bind:class="{ hide: !isFirst }">
      <div class="forma__all">
        <v-tooltip v-model="isHint" top>
          <template v-slot:activator="{}">
            <v-text-field
              hint="Please, start with your area code"
              label="Phone
            number (Will be your Username)"
              ref="phone"
              v-model="form.username"
              v-validate="'required|numeric|min:10|max:10'"
              data-vv-name="phone"
              :error-messages="errorsBags.collect('phone')"
              :rules="[phoneConfirmationRule]"
              outlined
              color="black"
              class="forma__input"
              style="margin-top: 3vw"
              v-on="{ focus, focusout }"
              v-on:input="PhoneCheck()"
            />
          </template>
          <span>Please, start with your area code</span>
        </v-tooltip>
        <v-text-field
          v-model="form.confirmed_username"
          label="Confirm phone number"
          data-vv-name="Confirm phone"
          v-validate="'required|numeric|min:10|max:10'"
          :rules="[phoneConfirmationRule]"
          :error-messages="errorsBags.collect('Confirm phone')"
          outlined
          color="black"
          class="forma__input"
          v-on:input="UserNameCheck()"
        />
        <v-text-field
          v-model="form.first_name"
          label="First name"
          data-vv-name="first name"
          v-validate="'required'"
          :error-messages="errorsBags.collect('first name')"
          outlined
          color="black"
          class="forma__input"
          v-on:input="checkValid()"
        />
        <v-text-field
          v-model="form.last_name"
          label="Last name"
          v-validate="'required'"
          data-vv-name="last name"
          :error-messages="errorsBags.collect('last name')"
          outlined
          color="black"
          class="forma__input"
          v-on:input="checkValid()"
        />
        <v-text-field
          label="Email address"
          v-model="form.email"
          data-vv-name="email"
          :rules="[emailConfirmationRule]"
          :error-messages="errorsBags.collect('email')"
          outlined
          color="black"
          class="forma__input"
          v-on:input="EmailCheck()"
        />
        <label class="check" style="margin: 20px 0" v-on:input="checkValid()">
          <input type="checkbox" v-model="email" />
          <span></span>
          I don't have email.
        </label>
        <div class="signup__buttons">
          <button @click="gob()">Back</button>
          <button @click="gosec()" v-bind:class="{ blockedbtn: !Valid }">
            {{ Valid ? "Next" : "Fill out" }}
          </button>
        </div>
      </div>
    </div>
    <div class="signup__slide" v-bind:class="{ hide: !isSecond }">
      <AmountOffer
        @choseOffer="choseOffer"
        :isSelectOffer="isSelectOffer"
        :offer_name="form.offer_name"
        :username="form.username"
        v-bind="{'username':form.username}"
      />
      <div class="signup__buttons">
        <button @click="gof()">Back</button>
        <button @click="goth()" v-bind:class="{ blockedbtn: !form.offer_name }">
          {{ form.offer_name ? "Next" : "Pick time" }}
        </button>
      </div>
    </div>
    <div class="signup__slide" v-bind:class="{ hide: !isThird }">
      <div class="forma__all">
        <div class="forma__savecard">
          <!-- <label class="check">
            <input type="checkbox" v-model="form.is_save_card" />
            <span></span>
            Save Card
          </label> -->
          <div class="forma__savecard__title">
            Credit cards saved automatically to your account after purchase
          </div>
        </div>
        <v-text-field
          v-model="form.name_on_card"
          outlined
          color="black"
          class="forma__input"
          label="Name on Card"
          v-validate="'required'"
          data-vv-name="name card"
          :error-messages="errorsBags.collect('name card')"
        ></v-text-field>
        <v-text-field
          v-model="cardNumberWithMask"
          outlined
          color="black"
          class="forma__input"
          label="Card Number"
          v-mask="'#### #### #### ####'"
          v-validate="'required|min:18|max:19'"
          data-vv-name="card number"
          :error-messages="errorsBags.collect('card number')"
          @blur="form.card_number = cardNumberWithMask.replace(/\s+/g, '')"
          append-icon="mdi-credit-card-check-outline"
        >
        </v-text-field>
        <div class="forma__ddmm">
          <v-text-field
            v-model="form.date_mm"
            v-mask="'##'"
            label="Month"
            v-validate="'required|numeric|min:1|max:2'"
            data-vv-name="Month"
            :error-messages="errorsBags.collect('Month')"
            outlined
            color="black"
            class="forma__ddmm__input"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="form.date_yy"
            v-mask="'####'"
            label="Year"
            v-validate="'required|year'"
            data-vv-name="Year"
            :error-messages="errorsBags.collect('Year')"
            outlined
            color="black"
            class="forma__ddmm__input"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="form.cvv"
            v-mask="'####'"
            label="CVV"
            v-validate="'required|numeric|min:3|max:4'"
            data-vv-name="cvv"
            :error-messages="errorsBags.collect('cvv')"
            outlined
            color="black"
            class="forma__ddmm__input"
            type="number"
          ></v-text-field>
        </div>
        <div class="signup__buttons">
          <button @click="gos()">Back</button>
          <button
            ref="submitButton"
            @click="buyOffer"
            v-bind:class="{ blockedbtn: dis }"
          >
            {{ !dis ? "Sign Up" : "Wait" }}
          </button>
          <button v-show="cashAvailable" class="full-width-button" @click="openPayCashDialog()">Pay by cash</button>

        </div>
      </div>
    </div>
    <v-dialog v-model="payCashDialog" max-width="500px">
      <CashPay
          :price=offer_time
          :custom-fields="cashFields"
          @closeCashPayDialog="closePayCashDialog"
          @confirmPay="confirmPayCash"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AmountOffer from "./common/AmountOffer";
import CashPay from "./common/modal/CashPay.vue";


export default {
  name: "SignUp",
  components: {CashPay, AmountOffer },
  data: () => ({
    isFirst: true,
    isSecond: false,
    isThird: false,
    isSelectOffer: false,
    dis: false,
    formWithNewCardIsShown: false,
    Valid: false,
    offer_time: "",
    form: {
      username: "",
      confirmed_username: "",
      first_name: "",
      last_name: "",
      email: "",
      name_on_card: "",
      card_number: "",
      date_mm: "",
      date_yy: "",
      cvv: "",
      offer_name: "",
      offer_id: null,
      use_saved_card: "",
      is_save_card: true,
    },
    cardNumberWithMask: "",
    dialog: false,
    isHint: false,
    phoneValid: false,
    usernameValid: false,
    emailValid: false,
    email: false,
    payCashDialog: false,
    cashAvailable : localStorage.cashAvailable === 'true',
  }),
    mounted() {
        this.$validator.extend('year', {
            validate: (value) => {
                const valueString = String(value);
                return valueString.length === 2 || valueString.length === 4;
            },
            getMessage(field){
                return ` The ${field} field must contain exactly 2 or 4 digits.`;
            }
        });
    },
  computed: {
    ...mapState(["offers"]),

    phoneConfirmationRule() {
      return () =>
        this.form.username === this.form.confirmed_username ||
        "Phone number must match";
    },
    emailConfirmationRule() {
      if (!this.email) {
        return () => this.emailValid || "email should be correct";
      }
      return true;
    },
    cashFields() {
      return {
        type: "signup",
        offer_id: this.form?.offer_id?.toString(),
        offer_name: this.form?.offer_name,
        originalPrice: this.offer_time?.toString(),
        username: this.form?.username.toString(),
        confirmed_username: this.form?.confirmed_username.toString(),
        first_name: this.form?.first_name.toString(),
        last_name: this.form?.last_name.toString(),
        email: this.form?.email.toString(),
      };
    },
  },
  created() {
    this.fetchOffers();
  },
  methods: {
    openPayCashDialog() {
      this.payCashDialog = true;
    },
    async confirmPayCash() {
      this.formWithNewCardIsShown = false;
      this.wait = false;
      this.setNotification({
        show: true,
        type: "success",
        message: "Your transaction was completed",
        description: "See further instructions for create password",
      });
      this.$router.push({ name: "successSignup" });
      this.$refs.submitButton.disabled = false;
      this.dis = false;
    },
    closePayCashDialog() {
      this.payCashDialog = false;
    },
    gob() {
      this.$router.go(-1);
    },
    gof() {
      this.isFirst = !this.isFirst;
      this.isSecond = !this.isSecond;
    },
    gos() {
      this.isSecond = !this.isSecond;
      this.isThird = !this.isThird;
    },
    async gosec() {
      if (this.Valid) {
        let response = await this.$axios.get("/api/check_user/"+this.form.username);
        if (response.status === 200) {
          this.isFirst = !this.isFirst;
          this.isSecond = !this.isSecond;
          if (this.email) this.form.email = "none@gmail.com";
        }
      }
    },
    goth() {
      if (this.form.offer_name) {
        this.isSecond = !this.isSecond;
        this.isThird = !this.isThird;
      }
    },
    EmailCheck() {
      let text = this.form.email;
      let reg = /[\w.]+@[\w-]+\.+[\w-]{2,4}/g;
      reg.test(text) ? (this.emailValid = true) : (this.emailValid = false);
      this.checkValid();
    },
    PhoneCheck() {
      let phone = this.form.username;
      (phone.length === 10)
        ? (this.phoneValid = true)
        : (this.phoneValid = false);
      this.checkValid();
    },
    UserNameCheck() {
      let phone = this.form.username,
        confirmed = this.form.confirmed_username;
      phone === confirmed
        ? (this.usernameValid = true)
        : (this.usernameValid = false);
      this.checkValid();
    },
    checkValid() {
      if (
        this.usernameValid &&
        this.phoneValid &&
        this.errorsBags.items.length < 1 &&
        this.form.first_name != "" &&
        this.form.last_name != ""
      ) {
        setTimeout(() => {
          if (!this.email && this.emailValid) this.Valid = true;
          else if (this.email) this.Valid = this.Valid = true;
          else this.Valid = false;
        }, 1);
      } else this.Valid = false;
    },
    focus() {
      this.isHint = true;
    },
    focusout() {
      this.isHint = false;
    },
    ...mapActions(["setNotification", "setCardNumber"]),
    ...mapMutations(["updateOffers"]),
    async fetchOffers() {
      try {
        //TODO: show only offers not for 4 digit
        const response = await this.$axios.get("/api/sloffers");

        if (response.status === 200) {
          const offers = response.data;
          const filteredOffers = offers.filter(
            (offer) => offer.four_digit === false
          );
          this.updateOffers(filteredOffers);
        }
        this.form.offer_name = null;
      } catch (err) {
        console.error(err);
      }
    },
    async buyOffer() {
      this.$refs.submitButton.disabled = true;
      this.dis = true;
      try {
        this.form.use_saved_card = false;
        const isValid =
          (await this.$validator.validate()) && this.$refs.offerForm.validate();
        if (!this.form.offer_name) {
          this.isSelectOffer = false;
        }
        if (isValid && this.isSelectOffer) {
          const payload = new FormData();
          for (const key in this.form) {
            payload.append(key, this.form[key]);
          }
          const response = await this.$axios.post(`/api/signup`, payload);
          if (response.status === 200) {
            this.setNotification({
              show: true,
              type: "success",
              message: response.data.message,
              description: "See further instructions for create password",
            });
            this.$router.push({ name: "successSignup" });
            this.$eventBus.$emit("updateUser", response.data.user);
            this.setCardNumber({
              cardNumber: response.data.user.cardNumber,
            });
          }
          this.formWithNewCardIsShown = false;
        }
      } catch (error) {
        console.error(error);
      }
      this.$refs.submitButton.disabled = false;
      this.dis = false;
    },
    clearForm() {
      Object.keys(this.form).forEach((key) => (this.form[key] = null));
      this.form.offer_name = null;
      this.cardNumberWithMask = null;
    },
    choseOffer(item) {
      if(item !== null) {
        this.isSelectOffer = true;
      this.form.offer_name = item.Name;
      this.form.offer_id = item.offer_id;
      this.offer_time = item.CostPrice;
      } else {
        this.isSelectOffer = false;
        this.form.offer_name = null;
        this.form.offer_id = null;
        this.offer_time = "";
      }
    }
  },
};
</script>

<style scoped>
.hide {
  display: none;
}
.blockedbtn {
  background-color: #7a7a7a;
}
.full-width-button{
  margin: 10px 0;
  width: 100%;
  background-color: #000000;
}
</style>
